




























































































































































































import Component, { mixins } from 'vue-class-component';

import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { Getter, namespace } from 'vuex-class';
import { ILogs, ILogsGraph, IPaginate, IUser } from '@/types/types';
import { dateFormat, truncateContent, loadWidget } from '@/utils/helpers';
import { Watch } from 'vue-property-decorator';
import moment from 'moment';

const historyModule = namespace('history');
const usersModule = namespace('users');

@Component({
  name: 'Index',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    DoubleDatePicker: loadWidget('widgets/DoubleDatePicker'),
  },
  filters: {
    dateFormat,
    truncateContent,
  },
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @historyModule.Getter('getPagination') paginate!: IPaginate & {
    limit: number;
  };
  @historyModule.Getter('getAllHistories') history!: ILogs[];
  @historyModule.Getter('getHistoryGraph') logsGraph!: Array<ILogsGraph>;
  @historyModule.Getter('getHistoryTitles') headers!: Array<{
    text: string;
    value: string;
  }>;
  @usersModule.Getter('getCustomerDetails') customerDetails!: IUser;

  tab = null;
  items = ['Top Up'];
  menu = false;
  renderChart = false;
  options: IPaginate = {
    itemsPerPage: 15,
    page: 1,
    total: 0,
  };
  breadcrumbs = [{ text: 'Sub Account', href: '/sub-accounts' }];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartOptions: Record<string, any> = {
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
      width: '100%',
      type: 'area',
      height: '400px',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        endingShape: 'rounded',
      },
    },
    colors: ['rgb(7, 143, 60)'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 3,
      colors: ['transparent'],
    },
    xaxis: {
      axisBorder: {
        show: true,
      },
      labels: {
        show: true,
        styles: {
          cssClass: 'ft font-weight-medium',
        },
      },
      axisTicks: { show: true },
      categories: [],
    },
    grid: {
      show: false,
    },
    yaxis: {
      axisBorder: { show: false },
      labels: {
        show: true,
        styles: {
          cssClass: 'ft font-weight-medium',
        },
        formatter: (value: number) => {
          return value.toFixed(0);
        },
      },
      axisTicks: { show: false },
    },
    fill: {
      opacity: 1,
    },
  };

  series: Array<{ name: string; data: number[] }> = [];

  dateFilter = '';
  page = 1;
  dataLoaderPlaceholder = 'Loading data ...';

  @Watch('page')
  onOptionsChange(page: number): void {
    this.$store.dispatch('history/listAllHistory', {
      page: page ?? 1,
      subAccountId: Number(this.$route.params.id),
      limit: this.paginate.limit ?? 15,
      query: this.dateFilter,
    });
  }

  @Watch('logsGraph')
  onLogsGraphChange(data: Array<ILogsGraph>): void {
    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        ...this.chartOptions.xaxis,
        categories: data.map(({ date }) => date),
      },
    };
    this.series = [
      {
        name: 'Total Top Ups',
        data: data.map(({ total_count }) => total_count),
      },
    ];
  }

  filterLogsByDate(date: string): void {
    this.page = 1;
    this.dateFilter = `&date=${date}`;
    this.$store.dispatch('history/listAllHistory', {
      page: this.page,
      subAccountId: Number(this.$route.params.id),
      limit: this.paginate.limit,
      query: this.dateFilter,
    });
  }

  created(): void {
    this.$nextTick(() => {
      this.renderChart = true;
    });
    this.page = 1;
    this.dateFilter = `&date=${moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`;
    this.$store.dispatch('history/listAllHistory', {
      subAccountId: this.$route.params?.id,
      page: this.page,
      limit: 15,
      query: this.dateFilter,
    });
    if (this.$route.params?.id) {
      this.$store.dispatch('users/customerDetails', {
        accountId: this.$route.params.id,
      });
    }
    // set data placeholder after 3 sec
    setInterval(() => {
      this.dataLoaderPlaceholder = '😔 Failed to load data';
    }, 2000);
  }
}
